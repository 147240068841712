import { mdiFileMultipleOutline, mdiBullhornOutline, mdiAccountGroupOutline, mdiDialpad } from '@mdi/js'

export default [
  {
    title: 'Announcement',
    icon: mdiBullhornOutline,
    to: 'admin.announcements'
  },
  {
    title: 'Templates',
    icon: mdiFileMultipleOutline,
    to: 'admin.templates'
  },
  {
    title: 'Users',
    icon: mdiAccountGroupOutline,
    to: 'admin.users'
  },
]
