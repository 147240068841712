<template>
  <layout-content-main :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot></slot>
  </layout-content-main>
</template>

<script>
import LayoutContentMain from "./LayoutContentMain";
import navMenuItems from '@/navigation/admin';

export default {
  components: {
    LayoutContentMain
  },
  setup() {
    return {
      navMenuItems
    }
  }
}
</script>

<style>

</style>